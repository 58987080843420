// src/components/ContactUs.js
import React from 'react';
import { Link } from 'react-router-dom';
import HamburgerMenu from '../Components/HamburgerMenu';
import LogoImage from '../Assets/logo.png'; // Update this path to your actual logo image

const ContactUs = () => {
  return (
    <>
      <div className="bg-[#BB005A] h-[100px] flex items-center justify-between px-8 z-10 relative">
        {/* Logo with Link */}
        <Link to="/">
          <img src={LogoImage} alt="Logo" className="h-12" /> 
        </Link>
        {/* Hamburger Menu */}
        <div className="mb-16">
          <HamburgerMenu />
        </div>
      </div>
      
      <div className="min-h-screen flex flex-col items-center p-8 md:p-16 bg-gray-100">
        <div className="w-full max-w-3xl text-center mb-8">
          <h1 className="text-4xl md:text-5xl font-bold text-[#333] mb-4">Get In Touch</h1>
          <p className="text-lg text-gray-600">
            We’d love to hear from you! Whether you have questions about enrollment, academic programs, or student support services, our team is here to assist you. Reach out to learn more about how we can support your child’s educational journey.
          </p>
        </div>

        <div className="flex flex-col md:flex-row md:justify-between w-full max-w-6xl bg-white shadow-lg rounded-lg p-8 gap-12">
          {/* Left Side: Contact Info and Address */}
          <div className="md:w-1/3 space-y-6">
            <div>
              <h2 className="text-2xl font-semibold text-[#BB005A]">Our Address</h2>
              <p className="mt-2 text-lg text-gray-700">
                4048 3rd Street <br />
                Dzivarasekwa 1, Harare
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-semibold text-[#BB005A]">Our Contact Info</h2>
              <p className="mt-2 text-lg text-gray-700">
                +263 24 2216757<br />
                oscschools@gmail.com
              </p>
            </div>
          </div>

          {/* Center: Map */}
          <div className="w-full md:w-2/3 h-[300px] md:h-[400px] bg-gray-200 rounded-lg overflow-hidden shadow-md">
            <iframe
              className="w-full h-full"
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3798.7658236218476!2d30.928949975174465!3d-17.80269958315699!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTfCsDQ4JzA5LjciUyAzMMKwNTUnNTMuNSJF!5e0!3m2!1sen!2szw!4v1730663355041!5m2!1sen!2szw"
              allowFullScreen=""
              loading="lazy"
              title="Brainwave Learning Solutions Location"
            ></iframe>
          </div>
        </div>

        {/* Bottom: Request a Demo Form */}
        {/* Add any additional form or footer content here if needed */}
      </div>
    </>
  );
};

export default ContactUs;
