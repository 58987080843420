import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Loader from './Components/Loader';

// Lazy-loaded components
import Homepage from './Pages/Homepage';
import Enrollment from './Pages/Enrollment';
import Aboutus from './Pages/Aboutus';
import Contactus from './Pages/Contactus';
import StudentLife from './Pages/StudentLife';
import RecentEvents from './Pages/RecentEvents';
import ArticlePage from './Pages/ArticlePage';

function App() {
  return (
    <Router>
      {/* Suspense wraps all routes and displays Loader while components load */}
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/enrollment" element={<Enrollment />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/student-life" element={<StudentLife />} />
          <Route path="/recent-events" element={<RecentEvents />} />
          <Route path="/article/:id" element={<ArticlePage />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
