import React from 'react';
import schoolLogo from '../Assets/logo.jpeg'; // Replace with your actual logo path

function Loader() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white">
      {/* Spinning animation */}
      <div className="animate-spin w-16 h-16 border-4 border-[#BB005A] border-t-transparent rounded-full mb-4"></div>
      <img src={schoolLogo} alt="Logo" className="w-20 h-20" />
      <p className="mt-4 text-lg font-semibold text-[#BB005A]">Loading...</p>
    </div>
  );
}

export default Loader;
