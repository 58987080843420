import React, { useState, useEffect } from 'react';
import HeroSection from '../Components/HeroSection';
import NextSection from '../Components/NextSection';
import CoreValuesSection from '../Components/CoreValuesSection';
import Headmaster from '../Assets/Headmaster.jpeg';
import Education from '../Assets/Education.jpeg';
import { Link } from 'react-router-dom';
import Footer from '../Components/Footer';
import ArtsImage from '../Assets/ArtsImage.jpeg';
import CommercialsImage from '../Assets/CommercialImage.jpeg';
import SciencesImage from '../Assets/ScienceImage.jpeg';
import SportsImage from '../Assets/sportsimage1.jpeg';
import { motion } from 'framer-motion';
import logo from '../Assets/logo.jpeg'
const HomePage = () => {
  const tabs = [
    { title: 'Arts', content: 'Explore creative disciplines such as visual arts, music, and drama.', image: ArtsImage },
    { title: 'Commercials', content: 'Develop skills in business studies, economics, and marketing.', image: CommercialsImage },
    { title: 'Sciences', content: 'Engage with subjects like biology, chemistry, physics, and mathematics.', image: SciencesImage },
    { title: 'Sports and Wellness', content: 'Promoting physical fitness and mental well-being through various sports.', image: SportsImage },
  ];

  // State for active tab
  const [activeTab, setActiveTab] = useState(0);

  // State for modal visibility
  const [isModalVisible, setIsModalVisible] = useState(true);

  // Automatically change tabs every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTab((prevTab) => (prevTab + 1) % tabs.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  // Event handler to close modal
  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="overflow-x-hidden">
      {/* Modal */}
      {isModalVisible && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg max-w-lg w-full text-center relative">
            {/* Close Button */}
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-white bg-[#BB005A] w-8 h-8 rounded-full flex items-center justify-center hover:bg-[#9A004E] transition"
            >
              &times;
            </button>
            {/* Modal Content */}
            <img src={logo} alt="School Logo" className="w-16 h-16 mx-auto mb-4" />
            <h2 className="text-xl md:text-2xl font-bold mb-4">Oxford Study Center</h2>
            <p className="text-sm md:text-lg">We are currently enrolling for 2025. Don't miss it!</p>
            <p className="text-xs md:text-sm mt-2">See the contact page for more information.</p>
            <button
              onClick={closeModal}
              className="mt-6 px-4 py-2 md:px-6 md:py-2 bg-[#BB005A] text-white rounded hover:bg-[#9A004E] transition"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Page Content */}
      <HeroSection />
      <NextSection />
      <CoreValuesSection />
      <div className="bg-white py-8 md:py-16 px-4 md:px-6">
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 items-center">
          {/* Left Text Section */}
          <div className="text-center md:text-left">
            <p className="text-[#BB005A] font-semibold uppercase tracking-wide text-sm md:text-base">
              Embracing Young People’s Curiosity
            </p>
            <h2 className="text-3xl md:text-5xl font-bold mt-2 mb-4 md:mb-6">Meet the Principal</h2>
            <p className="text-sm md:text-lg text-gray-700">
              As an educator and leader, I understand there are few decisions in life as important as the choice of school for
              your child. We want them to grow up to be healthy, capable adults; adaptable, resilient, and prepared for life
              beyond school.
            </p>
          </div>

          {/* Right Image Section */}
          <div className="relative">
            <img
              src={Headmaster}
              alt="Principal"
              className="rounded-lg shadow-lg w-full h-auto object-cover"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row p-4 md:p-8 bg-white text-black">
        {/* Left side tabs */}
        <div className="flex flex-col bg-white md:w-1/3 space-y-6">
          <h2 className="text-3xl md:text-5xl font-bold text-gray-800 mb-4 md:mb-6">Learning Areas</h2>

          {/* Vertical line with dots */}
          <div className="relative flex flex-col space-y-6">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className="flex items-center space-x-4 cursor-pointer"
                onClick={() => setActiveTab(index)}
              >
                {/* Dot */}
                <div className="relative flex flex-col items-center">
                  <span
                    className={`w-3 h-3 rounded-full ${
                      index === activeTab ? 'bg-[#BB005A]' : 'bg-gray-300'
                    }`}
                  />
                  {/* Line connecting dots */}
                  {index < tabs.length - 1 && (
                    <div
                      className={`w-px h-8 ${
                        index < activeTab ? 'bg-[#BB005A]' : 'bg-gray-300'
                      }`}
                    />
                  )}
                </div>
                {/* Tab title */}
                <span
                  className={`text-sm md:text-lg ${
                    index === activeTab ? 'font-bold text-[#BB005A]' : 'text-gray-700'
                  }`}
                >
                  {tab.title}
                </span>
              </div>
            ))}
          </div>
        </div>

        {/* Right side content */}
        <div className="md:w-2/3 mt-6 md:mt-0">
          {/* Image */}
          <div
            className="w-full h-[300px] md:h-[500px] rounded-lg bg-cover bg-center"
            style={{
              backgroundImage: `url(${tabs[activeTab].image})`,
            }}
          />
          {/* Content text */}
          <div className="p-4 md:p-8 text-left">
            <h3 className="text-xl md:text-3xl font-bold">{tabs[activeTab].title}</h3>
            <p className="text-sm md:text-lg mt-4">{tabs[activeTab].content}</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HomePage;
