import React from 'react'
import HamburgerMenu from '../Components/HamburgerMenu';
import LogoImage from '../Assets/logo.png'; // Update this path to your actual logo image
import EnrollmentImage from '../Assets/aboutus.jpeg'; // Update path if needed
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import { Link } from 'react-router-dom';
import Footer from '../Components/Footer';
import CurriculumImage2 from '../Assets/aboutus-corevalues.jpeg';
import Student1 from '../Assets/student1.png'; // Replace with actual image paths
import BackgroundImage from '../Assets/curricumlum (4).jpeg';

import Zimsec from '../Assets/zimsec.png'
import Cambridge from '../Assets/cambridge.png'
const Aboutus = () => {
  const { scrollY } = useViewportScroll();


  const subjects = [
    "Curriculum History", "F.R.S", "Technical Graphics and Design", "Shona", "English",
    "Heritage Studies", "Agriculture", "Computer Science", "Mathematics",
    "Geography", "Combined Science", "Business Enterprise Skills", "Economics",
    "Principles of Accounts"
  ];
  return (
    <div>
      <div className="bg-[#BB005A] h-[100px] flex items-center justify-between px-8 z-10 relative">
        {/* Logo with Link */}
        <Link to="/">
          <img src={LogoImage} alt="Logo" className="h-12" /> 
        </Link>
        {/* Hamburger Menu */}
        <div className="mb-16">
          <HamburgerMenu />
        </div>
      </div>
      <motion.div
  className="h-[542px] w-full flex items-end justify-start bg-cover bg-center text-white p-8 relative"
  style={{
    backgroundImage: `url(${EnrollmentImage})`,
  }}
>
  {/* Overlay for opacity */}
  <div className="absolute top-0 left-0 w-full h-full bg-black opacity-60"></div>

  {/* Content */}
  <div className="relative max-w-3/4 p-4 rounded">
    <h1 className="text-4xl font-bold mb-8">About Oxford Study Center</h1>
    <p className="text-lg mb-8">
      Oxford Study Centre is a registered academic institution with both The Ministry of Primary and
      Secondary Education and Zimsec. Its Tutors are Zimsec accredited examiners. We offer both Zimsec
      and Cambridge Curriculum. Our services include full-time tuition for form 1 to 6, after school remedial
      lessons, non-formal school, library facility.
    </p>
  </div>
</motion.div>

      <div className="bg-gray-100 py-16 px-6">
        {/* Title */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-[#BB005A]">Curriculum</h2>
          <p className="text-lg text-gray-600 mt-2">Explore our diverse range of subjects designed to inspire and educate.</p>
        </div>

        {/* Subject Grid */}
        <div className="max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {subjects.map((subject, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md text-center">
              <span className="text-lg font-semibold text-gray-800">{subject}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white py-16 px-6">
        {/* Section Header */}
        <div className="text-center max-w-4xl mx-auto mb-12">
          <h2 className="text-4xl font-bold text-gray-900">Core Values</h2>
          <p className="text-xl font-semibold text-gray-700 mt-2">
            Making today’s learning count in tomorrow’s workplace.
          </p>
          <p className="text-lg text-gray-600 mt-4">
            The infinite opportunities of the future will be the proving grounds of nimble young minds. So as parents and educators, we owe it to our young people to equip them today with the knowledge, skills, and attributes they will need to thrive tomorrow.
          </p>
        </div>

        {/* Core Value Section */}
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center space-y-6 md:space-y-0 md:space-x-8">
          {/* Image on the left */}
          <div className="w-full md:w-1/2">
            <img
              src={CurriculumImage2} // Replace with your actual image path
              alt="Core Value gg "
              className="rounded-lg shadow-lg object-cover w-full h-full"
            />
          </div>

          {/* Core Value Text */}
          <div className="w-full md:w-1/2 text-left">
            <h3 className="text-3xl font-bold text-gray-800">Community</h3>
            <p className="text-lg text-[#BB005A] italic mt-2">
              Investing in our people and relationships is a powerful agent for change.
            </p>
            <p className="text-gray-700 mt-4">
              While our staff and students come from far and wide, they come together with a true sense of pride and belonging—both in themselves and their community.
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="relative bg-white text-[#BB005A] py-16 px-6">
          {/* Content Wrapper */}
          <div className="relative max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Left Content (Introduction and Classes Section) */}
            <div className="text-left">
              <h2 className="text-4xl font-bold">Oxford Study Center</h2>
              <p className="text-lg mt-4">
                We offer a range of classes and activities designed to foster growth and development:
              </p>
              <ul className="space-y-2 mt-6">
                <li>✓ Arts & Humanities: Forms 1-6</li>
                <li>✓ Pure Sciences: Forms 3-6</li>
                <li>✓ Combined Science: Forms 1-4</li>
                <li>✓ Computers: Forms 1-6</li>
              </ul>
            </div>

            {/* Right Content (Student Image) */}
            <div className="flex items-center justify-center">
              <img
                src={Student1}
                alt="Student Engaged in Activities"
                className="w-full h-auto object-cover md:w-[500px]"
              />
            </div>
          </div>
        </div>

      </div>
      <div className=" py-16 px-6">
        {/* Heading */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-[#BB005A]">Our Partners</h2>
          <p className="text-lg text-gray-700 mt-4 max-w-3xl mx-auto">
            At Oxford Study Center, we are proud to partner with globally recognized educational bodies
            that uphold the highest standards in learning and assessment. Through our collaborations with
            Cambridge and ZIMSEC, we provide our students with diverse curricula and opportunities for
            both local and international academic qualifications.
          </p>
        </div>

        {/* Partner Logos Section */}
        <div className="max-w-4xl mx-auto grid grid-cols-1 sm:grid-cols-2 gap-8 items-center text-center">
          {/* Cambridge Partner */}
          <div>
            <img
              src={Zimsec}
              alt="Cambridge Partner Logo"
              className="w-full h-auto object-contain mx-auto"
            />
          </div>

          {/* ZIMSEC Partner */}
          <div>
            <img
              src={Cambridge}
              alt="ZIMSEC Partner Logo"
              className="w-full h-auto object-contain mx-auto"
            />
          </div>
        </div>
      </div>
  <Footer/>
    </div>
  )
}

export default Aboutus