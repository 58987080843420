// src/components/StudentLifeComponent.js
import React, { useEffect, useRef, useState } from 'react';
import CenterImage from '../Assets/curricumlum (5).jpeg';
import LeftImage1 from '../Assets/sportsimage1.jpeg';
import LeftImage2 from '../Assets/WhatsApp Image 2024-10-07 at 7.01.27 AM (2).jpeg';
import RightImage1 from '../Assets/uniform5.jpeg';
import RightImage2 from '../Assets/Education.jpeg';
import HamburgerMenu from '../Components/HamburgerMenu';
import LogoImage from '../Assets/logo.png';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import BackgroundImage1 from '../Assets/studentleadership.jpeg';
import BackgroundImage2 from '../Assets/Education.jpeg';
import BackgroundImage3 from '../Assets/studentleadership3.jpeg';
import { motion, AnimatePresence } from 'framer-motion';
import AcademicImage1 from '../Assets/academic.jpeg';
import AcademicImage2 from '../Assets/academic2.jpeg';
import AcademicImage3 from '../Assets/academic3.jpeg';
import AcademicImage4 from '../Assets/academic4.jpeg';
import AcademicImage5 from '../Assets/academic5.jpeg';
import AcademicImage6 from '../Assets/academic6.jpeg';
import AcademicImage7 from '../Assets/academic7.jpeg';
import AcademicImage8 from '../Assets/academic8.jpeg';
import AcademicImage9 from '../Assets/academic9.jpeg';
import Footer from '../Components/Footer';
import sportsImage1 from '../Assets/sportsimage1.jpeg'; // Replace with actual paths
import sportsImage2 from '../Assets/sportsimage2.jpeg';
import sportsImage3 from '../Assets/sportsimage3.jpeg';


const StudentLife = () => {
  const middleImageRef = useRef(null);
  const [isZoomed, setIsZoomed] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    {
      image: BackgroundImage1,
      text: "75% of our students participate in leadership programs. At our school, students are encouraged to develop their leadership potential through various roles, including becoming prefects. Prefects serve as role models, embodying the values of responsibility, integrity, and respect that we instill in all our students.",
    },
    {
      image: BackgroundImage2,
      text: "Our students lead by example in academics, sports, and community service. With a structured code of conduct and a commitment to discipline, we ensure a positive learning environment. Our strict rules support students in developing self-discipline and respect for others, fostering an atmosphere that encourages both personal and academic growth.",
    },
    {
      image: BackgroundImage3,
      text: "Join a community where leadership skills are nurtured every day. Through a variety of programs and mentorship opportunities, we focus on nurturing future leaders who are prepared to make a difference. Our school is dedicated to developing well-rounded individuals who excel not only academically but also as responsible, compassionate members of society.",
    },
  ];

  const academicSlides = [
    AcademicImage1,
    AcademicImage2,
    AcademicImage3,
    AcademicImage4,
    AcademicImage5,
    AcademicImage6,
    AcademicImage7,
    AcademicImage8,
    AcademicImage9,
  ];
  const handlePrev = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };



  useEffect(() => {
    const handleScroll = () => {
      if (middleImageRef.current) {
        const rect = middleImageRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const triggerPoint = windowHeight * 0.7;
        setIsZoomed(rect.top <= triggerPoint && rect.bottom >= triggerPoint);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 4) % academicSlides.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const visibleSlides = academicSlides.slice(currentIndex, currentIndex + 4);

  return (
    <div className="overflow-x-hidden">
      <div className="bg-[#BB005A] h-[100px] flex items-center justify-between px-8 z-20 relative">
        <Link to="/">
          <img src={LogoImage} alt="Logo" className="h-12" />
        </Link>
        <div className="mb-16">
          <HamburgerMenu />
        </div>
      </div>

      {/* Student Life Section */}
      <div className="w-full flex flex-col items-center py-32 bg-white text-center">
      <motion.h2
        className="text-6xl font-bold"
        style={{ lineHeight: '0.5', minHeight: '200px' }}
      >
        <motion.span
          initial={{ x: '-100vw', opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ type: 'spring', stiffness: 60, damping: 20 }}
          viewport={{ once: true }}
        >
          THIS IS{" "}
        </motion.span>
        <motion.span
          className="italic text-[#BB005A]"
          initial={{ x: '100vw', opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ type: 'spring', stiffness: 60, damping: 20, delay: 0.2 }}
          viewport={{ once: true }}
        >
          Student
        </motion.span>
        {" "}life
      </motion.h2>

        {/* Image Gallery */}
        <div className="relative flex justify-center items-center space-x-2 w-full">
          <div className="flex flex-col space-y-6">
            <img src={LeftImage1} alt="Student Life Left" className="rounded-lg w-48 h-48 object-cover" />
            <img src={LeftImage2} alt="Student Life Left 2" className="rounded-lg w-48 h-48 object-cover" />
          </div>
          <div
            ref={middleImageRef}
            className={`rounded-lg transition-transform duration-700 ${isZoomed ? 'transform scale-125 z-10' : ''}`}
            style={{ width: isZoomed ? '800px' : '300px', height: isZoomed ? '500px' : '300px', overflow: 'hidden' }}
          >
            <img src={CenterImage} alt="Student Life Center" className="w-full h-full object-cover" />
          </div>
          <div className="flex flex-col space-y-6">
            <img src={RightImage1} alt="Student Life Right 1" className="rounded-lg w-48 h-48 object-cover" />
            <img src={RightImage2} alt="Student Life Right 2" className="rounded-lg w-48 h-48 object-cover" />
          </div>
        </div>
      </div>

      {/* Academic Section */}
      <div className="text-center p-8">
        {/* Header Section */}
        <div className="mb-8">
          <h2 className="text-4xl font-bold text-[#BB005A]">Our Academic Excellence</h2>
          <p className="text-lg text-gray-700 mt-2">
            At Oxford Study Center, we are committed to academic excellence and holistic education. Our diverse academic offerings provide students with the tools they need to excel in their studies, develop critical thinking skills, and prepare for a future of success and leadership. Join us to foster growth, resilience, and lifelong learning.
          </p>
        </div>

        {/* Image Carousel */}
        <div className="overflow-hidden h-[200px] md:h-[300px]">
          <div className="flex gap-4 animate-scroll w-full" style={{ animationDuration: '30s' }}>
            {[...academicSlides, ...academicSlides].map((slide, index) => (
              <img
                key={index}
                src={slide}
                alt={`Academic slide ${index + 1}`}
                className="h-full rounded-lg object-cover w-1/4 md:w-1/6 lg:w-1/8"
              />
            ))}
          </div>
        </div>

        <style jsx>{`
    @keyframes scroll {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-50%);
      }
    }
    .animate-scroll {
      display: flex;
      animation: scroll 30s linear infinite;
    }
  `}</style>
      </div>


      {/* Student Leadership Section */}
      <div
  className="relative w-full h-[600px] md:h-[700px] flex flex-col md:flex-row items-center text-white"
  style={{
    backgroundImage: `url(${slides[currentSlide].image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }}
>
  {/* Dark Overlay */}
  <div className="absolute inset-0 bg-black opacity-70"></div>

  {/* Content Section */}
  <div className="relative z-10 flex flex-col md:flex-row w-full items-center md:items-start p-6 md:p-12 space-y-6 md:space-y-0">
    {/* Image and Controls */}
    <motion.div
      className="w-full md:w-3/5 flex flex-col items-center space-y-4"
      whileInView={{ opacity: 1, y: 0 }}
      initial={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex flex-col items-center mb-4 md:mb-8">
        <img
          src={slides[currentSlide].image}
          alt={`Slide ${currentSlide + 1}`}
          className="w-full max-w-[300px] md:max-w-[600px] h-auto rounded-lg mt-4 md:mt-10 border-2 border-white transition transform md:scale-110"
        />
      </div>
      <div className="flex items-center space-x-4">
        <button
          onClick={handlePrev}
          className="text-xl md:text-2xl bg-gray-800 p-2 rounded-full hover:bg-gray-600 transition"
        >
          <FaArrowLeft />
        </button>
        <button
          onClick={handleNext}
          className="text-xl md:text-2xl bg-gray-800 p-2 rounded-full hover:bg-gray-600 transition"
        >
          <FaArrowRight />
        </button>
      </div>
    </motion.div>

    {/* Text Section */}
    <motion.div
      className="w-full md:w-2/5 text-center md:text-left"
      whileInView={{ opacity: 1, x: 0 }}
      initial={{ opacity: 0, x: 50 }}
      transition={{ duration: 0.5, delay: 0.2 }}
    >
      <h2 className="text-2xl md:text-6xl font-bold mb-4 md:mb-6">Student Leadership</h2>
      <p className="text-sm md:text-lg font-semibold leading-relaxed">{slides[currentSlide].text}</p>
    </motion.div>
  </div>
</div>



      <div className="text-center py-16 bg-white">
      {/* Header with Framer Motion animation */}
      <motion.h2
        className="text-4xl font-bold text-[#002f5c]"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        SPORTS AND <span className="italic text-orange-500">WELLNESS</span>
      </motion.h2>
      <motion.p
        className="text-lg text-gray-600 mt-4 max-w-2xl mx-auto"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        At our school, we promote a balanced approach to health and physical activity. Our sports programs encourage teamwork, resilience, and physical fitness, while fostering a spirit of camaraderie and competition. Join us in pursuing a healthy lifestyle and achieving athletic excellence.
      </motion.p>

      {/* Image Grid */}
      <div className="flex flex-col md:flex-row gap-4 mt-10 px-4 md:px-0 max-w-6xl mx-auto">
        {/* First Image */}
        <div className="relative w-full md:w-1/3 overflow-hidden">
          <motion.img
            src={sportsImage1}
            alt="Team Spirit"
            className="w-full h-64 object-cover rounded-lg shadow-lg transition-transform duration-300 hover:scale-105"
            whileHover={{ scale: 1.1 }}
          />
          <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-60 text-white text-lg font-semibold py-2 text-center rounded-b-lg">
            TEAM SPIRIT
          </div>
        </div>

        {/* Second Image */}
        <div className="relative w-full md:w-1/3 overflow-hidden">
          <motion.img
            src={sportsImage2}
            alt="Healthy Lifestyle"
            className="w-full h-64 object-cover rounded-lg shadow-lg transition-transform duration-300 hover:scale-105"
            whileHover={{ scale: 1.1 }}
          />
          <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-60 text-white text-lg font-semibold py-2 text-center rounded-b-lg">
            HEALTHY LIFESTYLE
          </div>
        </div>

        {/* Third Image */}
        <div className="relative w-full md:w-1/3 overflow-hidden">
          <motion.img
            src={sportsImage3}
            alt="Competitive Spirit"
            className="w-full h-64 object-cover rounded-lg shadow-lg transition-transform duration-300 hover:scale-105"
            whileHover={{ scale: 1.1 }}
          />
          <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-60 text-white text-lg font-semibold py-2 text-center rounded-b-lg">
            COMPETITIVE SPIRIT
          </div>
        </div>
      </div>
    </div>
     <Footer/>
    </div>
  );
};

export default StudentLife;
