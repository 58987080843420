import React from 'react';
import { motion } from 'framer-motion';
import NextSectionImage from '../Assets/nextsection.png';
import { Link } from 'react-router-dom';

const NextSection = () => {
  return (
    <div className="relative flex flex-col md:flex-row items-start justify-between p-[8%] bg-white">
      {/* Left Side: Text Section */}
      <motion.div
        className="md:w-1/2 mr-8"
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        <h1 className="text-5xl font-bold text-black leading-tight">
          Making today’s learning count in tomorrow’s workplace
        </h1>
        <p className="mt-4 text-lg text-gray-700">
          The infinite opportunities of the future will be the proving grounds of nimble young minds.
          As parents and educators, we owe it to our young people to equip them today with the
          knowledge, skills, and attributes they will need to thrive tomorrow.
        </p>
        <Link to="/aboutus" className="mt-6 inline-block text-lg text-[#BB005A] font-bold">
          ABOUT OUR PROGRAM
        </Link>
      </motion.div>

      {/* Right Side: Image and Thick Wavy Lines */}
      <motion.div
        className="relative md:w-1/2 flex flex-col items-center justify-start mt-8 md:mt-0"
        initial={{ opacity: 0, x: 50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        {/* Many Thick Wavy Lines */}
        <div className="absolute top-[-50px] left-[-100px] h-[500px] w-[300px] z-0">
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 200 500"
            xmlns="http://www.w3.org/2000/svg"
          >
            {Array.from({ length: 12 }, (_, i) => (
              <path
                key={i}
                d={`M${i * 25},0 C${i * 25 + 30},50 ${i * 25 + 70},50 ${i * 25 + 90},100 C${i * 25 + 70},150 ${i * 25 + 30},150 ${i * 25},200 C${i * 25 + 30},250 ${i * 25 + 70},250 ${i * 25 + 90},300 C${i * 25 + 70},350 ${i * 25 + 30},350 ${i * 25},400`}
                stroke="#BB005A"
                strokeWidth="10"
                fill="none"
              />
            ))}
          </svg>
        </div>

        {/* Image Section */}
        <img
          src={NextSectionImage}
          alt="Students"
          className="rounded-lg shadow-lg w-full relative ml-10 z-10"
        />

        {/* Quick Links Section - Hidden on Mobile */}
        <div className="hidden md:block w-full mt-8">
          <h2 className="text-xl font-bold text-[#BB005A]">QUICKLINKS</h2>
          <div className="mt-6 space-y-4">
            <Link to="/enrollment" className="flex justify-between items-center text-lg text-gray-600 border-b pb-2">
              Enrolling at Our School
            </Link>
            <Link to="/contactus" className="flex justify-between items-center text-lg text-gray-600 border-b pb-2">
              Learn about Engagement & Wellbeing
            </Link>
            <Link to="/aboutus" className="flex justify-between items-center text-lg text-gray-600 border-b pb-2">
              State-of-the-Art Facilities
            </Link>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default NextSection;
