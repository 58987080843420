// src/components/CoreValuesSection.js
import React, { useState } from 'react';
import Image1 from '../Assets/corevalues1.jpeg'; // Replace with actual image paths
import Image2 from '../Assets/corevalues2.jpeg';
import Image3 from '../Assets/corevalues3.jpeg';
import Logo from '../Assets/logo.png'; // Replace with actual logo path

const coreValues = [
  {
    name: 'Community',
    description:
      'While our staff and students come from far and wide, they come together as one family, creating a strong sense of belonging.',
    image: Image1,
  },
  {
    name: 'Curiosity',
    description:
      'We foster curiosity, inspiring our students to explore new ideas and ways of thinking.',
    image: Image2,
  },
  {
    name: 'Excellence',
    description:
      'Striving for excellence in everything we do, pushing boundaries and achieving outstanding results.',
    image: Image3,
  },
];

const CoreValuesSection = () => {
  const [activeValue, setActiveValue] = useState(coreValues[0]); // Default to first value

  return (
    <div
      className="relative flex flex-col md:flex-row items-start p-10 bg-gradient-to-r from-gray-800 to-black"
    >
      {/* Our DNA Title */}
      <div className="absolute top-5 left-5 mt-5 text-white text-4xl font-bold z-10">
        Our Values
      </div>

      {/* Left Side: Core Values List */}
      <div className="md:w-1/2 border-l-4 fl  border-[#BB005A] pl-4 mt-16">
        <ul className="space-y-6">
          {coreValues.map((value, index) => (
            <li
              key={index}
              className={`flex items-center justify-between cursor-pointer text-xl ${
                activeValue.name === value.name ? 'text-[#BB005A] font-semibold' : 'text-gray-400'
              } border-b border-gray-600 pb-2`}
              onClick={() => setActiveValue(value)}
            >
              {value.name}
              {activeValue.name === value.name && (
                <span className="ml-2 text-[#BB005A]">&rarr;</span>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Right Side: Image and Description */}
      <div className="md:w-1/2 mt-8 md:mt-0 md:ml-10 relative">
        {/* Image Section */}
        <div className="relative flex items-start">
          <img
            src={activeValue.image}
            alt={activeValue.name}
            className="rounded-lg shadow-lg w-full mb-6"
          />

          {/* Navigation Dots */}
          <div className="absolute justify-end bottom-[-10px] left-1/2 transform -translate-x-1/2 flex space-x-2">
            {coreValues.map((_, index) => (
              <span
                key={index}
                className={`h-3 w-3 rounded-full cursor-pointer ${
                  activeValue.name === coreValues[index].name
                    ? 'bg-[#BB005A]'
                    : 'bg-gray-400'
                }`}
                onClick={() => setActiveValue(coreValues[index])}
              ></span>
            ))}
          </div>
        </div>

        {/* Description and Logo */}
        <div className="flex justify-between items-center mt-4">
          <div className="w-2/3">  {/* Fixed width for description */}
            <h3 className="text-2xl font-bold mb-4 text-white">{activeValue.name}</h3>
            <p className="text-lg text-gray-300">{activeValue.description}</p>
          </div>

          {/* Logo aligned with description and image */}
          <div className="ml-auto">  {/* Adjust alignment */}
            <img src={Logo} alt="Logo" className="w-16 h-16 object-contain" /> {/* Logo with fixed size and proper alignment */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoreValuesSection;
