import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaFacebookF, FaInstagram, FaTwitter, FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null); // For handling the active main link
  const [isMobile, setIsMobile] = useState(false); // State to track if it's mobile view

  // Check for mobile view on component mount and resize
  useEffect(() => {
    const checkMobileView = () => {
      setIsMobile(window.innerWidth <= 768); // Mobile view if screen width is <= 768px
    };

    checkMobileView(); // Check on mount
    window.addEventListener('resize', checkMobileView); // Check on resize

    return () => window.removeEventListener('resize', checkMobileView); // Cleanup on unmount
  }, []);

  // Toggle the menu open and close
  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setActiveMenu(null); // Reset active menu when toggling
  };

  // Handle the click of main links to reveal sub-links
  const handleMainLinkClick = (index) => {
    setActiveMenu(activeMenu === index ? null : index); // Toggle active menu
  };

  const mainLinks = [
    { title: 'About', link: '/aboutus' },
    { title: 'News & Events', link: '/recent-events' },
    { title: 'Student Life', link: '/student-life' },
    { title: 'Enrollment', link: '/enrollment' },
    { title: 'Connect', link: '/contactus' },
  ];

  const subLinks = {
    0: [
      { title: 'About Us', link: '/aboutus' },
      { title: 'Curriculum', link: '/aboutus' },
      { title: 'Our Core Values', link: '/aboutus' },
      { title: 'Our Partners', link: '/aboutus' },
    ],
    1: [
      { title: 'School Trips', link: '/recent-events' },
      { title: 'Reach Outs', link: '/recent-events' },
      { title: 'School Workshops', link: '/recent-events' },
    ],
    2: [
      { title: 'Academics', link: '/student-life' },
      { title: 'Student Leadership', link: '/student-life' },
      { title: 'Sports', link: '/student-life' },
    ],
    3: [
      { title: 'Enrollment', link: '/enrollment' },
      { title: 'Why Choose Us', link: '/enrollment' },
      { title: 'Requirements', link: '/enrollment' },
      { title: 'Fees', link: '/enrollment' },
      { title: 'Uniforms', link: '/enrollment' },
    ],
    4: [
      { title: 'Contact Us', link: '/contactus' },
      { title: 'Ask Questions', link: '/contactus' },
      { title: 'Queries', link: '/contactus' },
      { title: 'Suggestions', link: '/contactus' },
    ],
  };

  return (
    <div className="relative">
      {/* Hamburger icon and Menu text */}
      <button
        className="text-white absolute top-5 right-5 z-50 flex items-center space-x-2"
        onClick={toggleMenu}
      >
        {isOpen ? (
          <div className="flex items-center space-x-2">
            <span className="text-7xl">&times;</span> {/* Larger Close button */}
          </div>
        ) : (
          <div className="space-y-2">
            <div className="w-10 h-1 md:h-2 bg-white"></div>
            <div className="w-10 h-1 md:h-2 ml-2 bg-white"></div>
            <div className="w-10 h-1 md:h-2 ml-4 bg-white"></div>
          </div>
        )}
      </button>

      {/* Overlay navigation when open */}
      <motion.div
        className={`fixed inset-0 bg-black bg-opacity-90 z-40 ${isOpen ? 'block' : 'hidden'}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="flex h-full">
          {/* Main Links */}
          <div className="flex flex-col font-extrabold space-y-6 p-10 text-left w-full md:w-2/5 items-start justify-center">
            {mainLinks.map((mainLink, index) => (
              <div key={index} className="relative">
                {isMobile ? (
                  <Link
                    to={mainLink.link}
                    className="text-2xl md:text-5xl font-bold text-white block hover:text-[#BB005A] transition duration-200"
                    onClick={toggleMenu} // Close menu on link click
                  >
                    0{index + 1} <span className="ml-4">{mainLink.title}</span>
                  </Link>
                ) : (
                  <div>
                    <span
                      className={`text-5xl font-bold ${
                        activeMenu === index ? 'text-[#BB005A]' : 'text-white'
                      }`}
                    >
                      0{index + 1}
                    </span>
                    <button
                      onClick={() => handleMainLinkClick(index)}
                      className={`text-4xl ml-4 text-left ${
                        activeMenu === index ? 'text-[#BB005A]' : 'text-white'
                      }`}
                    >
                      {mainLink.title}
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Vertical Divider - Only for Desktop */}
          {!isMobile && (
            <div
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#BB005A]"
              style={{ width: '10px', height: '500px' }}
            ></div>
          )}

          {/* Sub-links */}
          {!isMobile && activeMenu !== null && (
            <div className="flex mr-5 mt-[10%] flex-col space-y-4 p-10 text-white w-1/2 mx-auto text-left">
              {subLinks[activeMenu]?.map((subLink, index) => (
                <Link
                  key={index}
                  to={subLink.link}
                  className="text-2xl cursor-pointer hover:text-[#BB005A] transition duration-200"
                >
                  {subLink.title}
                </Link>
              ))}
            </div>
          )}

          {/* Social Media and "Tomorrow — Today" */}
          <div className="absolute bottom-10 right-10 text-white flex flex-col space-y-4">
            <div className="text-right flex flex-col">
              <span className="text-4xl md:text-5xl text-[#BB005A] font-bold">Tomorrow</span>
              <span className="text-4xl md:text-5xl text-[#BB005A] font-bold">Today</span>
            </div>
            <div className="flex space-x-6 text-right justify-end">
              <a href="https://www.facebook.com" className="text-white text-2xl md:text-3xl bg-[#BB005A] p-4 rounded-full">
                <FaFacebookF />
              </a>
              <a href="https://www.instagram.com" className="text-white text-2xl md:text-3xl bg-[#BB005A] p-4 rounded-full">
                <FaInstagram />
              </a>
              <a href="https://twitter.com" className="text-white text-2xl md:text-3xl bg-[#BB005A] p-4 rounded-full">
                <FaTwitter />
              </a>
              <a href="mailto:info@osc.co.zw" className="text-white text-2xl md:text-3xl bg-[#BB005A] p-4 rounded-full">
                <FaEnvelope />
              </a>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default HamburgerMenu;
