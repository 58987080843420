// src/components/HeroSection.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Hero1 from '../Assets/Hero2.jpeg';
import Hero2 from '../Assets/Hero1.jpeg';
import Hero3 from '../Assets/Hero3.jpeg';
import Logo from '../Assets/logo.png'; // Import your logo
import HamburgerMenu from './HamburgerMenu'; // Import the Hamburger menu
import { Link } from 'react-router-dom';
const HeroSection = () => {
  const images = [Hero1, Hero2, Hero3]; // Array of hero images
  const catchphrases = [
    { top: "Tomorrow", bottom: "Today" },
    { top: "Shaping", bottom: "Future Leaders" },
    { top: "Inspiring", bottom: "Excellence" }
  ];

  const [currentPhrase, setCurrentPhrase] = useState(0);
  const [currentImage, setCurrentImage] = useState(0);

  // Cycle through the phrases every 3 seconds
  useEffect(() => {
    const phraseInterval = setInterval(() => {
      setCurrentPhrase((prevPhrase) => (prevPhrase + 1) % catchphrases.length);
    }, 3000);

    return () => clearInterval(phraseInterval); // Clean up on unmount
  }, [catchphrases.length]);

  // Cycle through the background images every 5 seconds
  useEffect(() => {
    const imageInterval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 8000);

    return () => clearInterval(imageInterval); // Clean up on unmount
  }, [images.length]);

  return (
    <div
      className="relative p-2 h-screen bg-center bg-no-repeat bg-cover transition-all duration-1000 overflow-hidden"
      style={{ backgroundImage: `url(${images[currentImage]})` }}
    >
      {/* Dark overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-30"></div>
      <div className='md:mt-0 mt-5'>
      <HamburgerMenu />

      </div>
      {/* Hamburger Menu */}

      {/* Sliding text from the left */}
      <motion.div
        className="absolute top-10 left-5 md:left-10 text-white z-10"
        initial={{ x: -200, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h1 className="text-2xl md:text-5xl w-[90%] md:w-full font-bold">
          OXFORD STUDY CENTRE
        </h1>
        <p className="text-sm md:text-xl">Explore • Learn • Succeed</p>
        <img src={Logo} alt="Logo" className="mt-4 w-20 h-auto md:w-32" />
      </motion.div>

      {/* Sliding catchphrases from the right */}
      <motion.div
        className="absolute bottom-10 right-5 md:right-10 text-white z-10 text-right"
        initial={{ x: 200, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h2 className="text-3xl md:text-6xl font-bold leading-none">
          {catchphrases[currentPhrase].top} <br />
          <span className="mt-2 block">{catchphrases[currentPhrase].bottom}</span>
        </h2>

        {/* Dots Navigation */}
        <div className="flex justify-end mt-4">
          {catchphrases.map((_, index) => (
            <span
              key={index}
              onClick={() => setCurrentPhrase(index)}
              className={`cursor-pointer w-3 h-3 rounded-full mx-1 md:mx-2 ${currentPhrase === index ? 'bg-white' : 'bg-gray-400'
                }`}
            ></span>
          ))}
        </div>
      </motion.div>

      {/* Explore Button */}
      <Link
        to="/aboutus"
        smooth={true}
        duration={500}
        className="absolute bottom-5 left-5 md:left-10 bg-[#BB005A] text-white px-6 py-3 rounded-full cursor-pointer text-sm md:text-base"
      >
        Explore
      </Link>
    </div>

  );
};

export default HeroSection;
