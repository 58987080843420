import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import CareerDay from '../Assets/CareerDay.jpeg';
import CultureDay from '../Assets/CultureDay.jpeg';
import HigherAchievers from '../Assets/HigherAchievers.jpeg';
import VictoriBinga from '../Assets/VictoriBinga.jpeg';
import Workshop from '../Assets/Workshop.jpeg';
import HamburgerMenu from '../Components/HamburgerMenu';
import LogoImage from '../Assets/logo.png';
import { Link } from 'react-router-dom';



const articles = [
  {
    id: 1,
    title: "Career Day at Oxford Study Center: Inspiring Futures and Building Dreams",
    date: "Term 3, 2024",
    category: "School Events",
    author: "By OSC",
    image: CareerDay,
    description: `At Oxford Study Center, we believe in empowering our students to explore and pursue their career passions. We recently held our annual Career Day event, where students had the unique opportunity to interact with professionals from diverse fields. This year, we welcomed a dynamic group of speakers, including industry leaders, educators, entrepreneurs, and alumni who shared insights from their respective careers and industries.

    The day began with an inspiring opening talk that emphasized the importance of resilience, hard work, and adaptability in today's fast-paced world. Students were captivated by stories of real-world experiences, and many left motivated to explore new career paths.
    
    Throughout the day, students attended breakout sessions covering a wide array of professions—from technology and healthcare to arts and public service. Each session allowed students to ask questions, gain practical advice, and learn about the skills and education required to succeed in these fields. These interactive sessions not only provided valuable information but also fostered a sense of curiosity and enthusiasm about the future.
        
    Career Day concluded with a reflection session, where students shared their thoughts on the event. Many expressed gratitude for the chance to explore careers in a supportive environment and felt inspired by the mentors they met. It was a day filled with learning, inspiration, and hope for the future—a true reflection of Oxford Study Center's commitment to guiding students toward fulfilling and successful careers.
    
    We are incredibly proud of our students' engagement and curiosity and look forward to seeing them thrive in the paths they choose!`,
  },
  {
    id: 2,
    title: "Culture Day at Oxford Study Center: Celebrating Heritage and Diversity",
    date: "Term 3, 2024",
    category: "Arts",
    author: "By admin",
    image: CultureDay,
    description: `At Oxford Study Center, we believe in the importance of connecting with our roots and celebrating the vibrant cultures that make up our school community. Our recent Culture Day was a joyful celebration of heritage, tradition, and unity, bringing students, staff, and families together to honor the richness of African culture.
      
      Students arrived in colorful African attire, proudly displaying the unique clothing styles of various cultures across the continent. It was a beautiful sight to see everyone embracing their heritage and showcasing the diversity within our school. The day was filled with exciting activities, traditional music, dance performances, and delicious local dishes, giving everyone a chance to experience and appreciate the customs of different African regions.
      
      A highlight of the event was the "Miss Oxford" pageant, where students competed for the titles of Miss Oxford, 1st Princess, and 2nd Princess. The participants dazzled on stage, embodying confidence and pride in their cultural identities. Each contestant shared something unique about their heritage, making the pageant not only a fun competition but also an educational experience for all.
      
      Culture Day is more than just a celebration—it is an opportunity for students to deepen their understanding of their heritage, fostering a sense of pride in their roots. In an increasingly globalized world, it is vital for young people to connect with their cultural backgrounds, as it shapes their identity and strengthens their sense of belonging.
      
      We are incredibly proud of our students for embracing this day with such enthusiasm, and we look forward to future celebrations of diversity and unity at Oxford Study Center.`,
  }
  ,
  {
    id: 3,
    title: "Higher Achievers at Oxford Study Center: Celebrating Excellence and Dedication",
    date: "Term 3, 2024",
    category: "School Events",
    author: "By OSC",
    image: HigherAchievers,
    description: `At Oxford Study Center, we are committed to recognizing and celebrating the hard work, dedication, and accomplishments of our students. Our annual "Higher Achievers" event, held at the end of Term 3, is a prestigious Prize Giving Day dedicated to honoring students who have excelled in academics, sports, arts, and leadership.
      
      The ceremony brought together students, teachers, parents, and the entire school community to celebrate the outstanding achievements of our learners. Awards were given to students who demonstrated exceptional performance and dedication in their studies, as well as to those who showcased exemplary talent and leadership across various fields.
      
      Highlights of the day included awards for top performers in each class, recognition of exemplary leadership roles, and special honors for students who went above and beyond in extracurricular activities. The atmosphere was filled with pride and excitement as each student was called to the stage to receive their award, greeted with applause from the audience.
      
      At Oxford Study Center, we believe that recognizing excellence inspires students to reach even greater heights. Our "Higher Achievers" ceremony not only celebrates individual accomplishments but also encourages a culture of hard work, perseverance, and the pursuit of personal bests. By honoring these achievements, we hope to inspire all our students to strive for excellence in all their endeavors.
      
      We are incredibly proud of our high achievers and look forward to seeing what they will accomplish in the future!`,
  }
  ,
  {
    id: 4,
    title: "Trip to Victoria Falls and Binga: Exploring Nature and Expanding Horizons",
    date: "Term 3, 2024",
    category: "School Trips",
    author: "By Nisarg",
    image: VictoriBinga,
    description: `At Oxford Study Center, we understand the value of experiential learning and believe that education goes beyond the classroom. Recently, our students embarked on an unforgettable journey to the breathtaking Victoria Falls and the serene Binga region, where they explored natural wonders and immersed themselves in cultural experiences.
      
      The trip was filled with exciting activities that offered students a unique blend of adventure and learning. At Victoria Falls, students witnessed one of the Seven Natural Wonders of the World, marveling at the stunning waterfall and learning about its geological significance and environmental impact. This experience gave our students a newfound appreciation for natural beauty and the importance of conservation.
      
      In Binga, students had the chance to interact with local communities, experiencing the rich traditions and cultural heritage of the region. They learned about local customs, crafts, and the ways in which people in the area have adapted to their environment over generations. This cultural exchange helped broaden their perspectives and fostered a sense of respect and appreciation for diverse lifestyles.
      
      School trips like this are not just about travel—they are opportunities for students to develop independence, build friendships, and deepen their understanding of the world. These experiences encourage curiosity, critical thinking, and personal growth, making education a more holistic and enriching journey.
      
      We are proud to offer such transformative experiences to our students, helping them to create lasting memories while expanding their horizons and building lifelong connections with the world around them.`,
  }
  ,
  {
    id: 5,
    title: "SLIZ Workshop in Nyanga: Enhancing Skills and Strengthening Teamwork",
    date: "Term 3, 2024",
    category: "Trips",
    author: "By admin",
    image: Workshop,
    description: `Recently, the staff and teachers of Oxford Study Center participated in an enriching SLIZ (Sports Leaders Institute of Zimbabwe) workshop held in the scenic town of Nyanga. This workshop provided an invaluable opportunity for our team to come together, develop new skills, and engage in collaborative activities aimed at enhancing the educational experience for our students.
      
      The workshop sessions focused on a wide array of topics, including modern teaching strategies, team-building exercises, and innovative ways to incorporate physical education and wellness into our curriculum. Our educators explored new techniques for fostering student engagement, promoting holistic development, and creating an inclusive learning environment.
      
      Apart from the training sessions, the natural beauty of Nyanga offered a peaceful setting for our team to recharge and reconnect. The staff enjoyed nature walks, group discussions, and shared experiences, which fostered a sense of camaraderie and teamwork that will benefit our school community.
      
      Workshops like these are essential for professional development, as they allow our staff to stay updated with the latest educational practices and bring fresh perspectives back to the classroom. By investing in our teachers' growth, we ensure that Oxford Study Center continues to provide a nurturing and dynamic learning environment for all our students.
      
      We are proud of our educators’ commitment to continuous improvement and look forward to seeing the positive impact of this workshop on our school community.`,
  }


];

const RecentEvents = () => {
  const navigate = useNavigate();

  const openArticlePage = (id) => {
    navigate(`/article/${id}`);
  };

  return (
    <>
      <div className="bg-[#BB005A] h-[100px] flex items-center justify-between px-8 z-10 relative">
        {/* Logo with Link */}
        <Link to="/">
          <img src={LogoImage} alt="Logo" className="h-12" />
        </Link>
        {/* Hamburger Menu */}
        <div className="mb-16">
          <HamburgerMenu />
        </div>
      </div>
      <div className="w-full p-6 text-center">
        <h2 className="text-2xl font-semibold mb-8">Browse some of our latest articles...</h2>
        <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {articles.map((article) => (
            <motion.div
              key={article.id}
              className="bg-white rounded-lg shadow-lg overflow-hidden cursor-pointer transform transition-transform duration-300 hover:scale-105"
              onClick={() => openArticlePage(article.id)}
            >
              <img src={article.image} alt={article.title} className="w-full h-48 object-cover" />
              <div className="p-4 text-left">
                <h3 className="text-lg font-bold">{article.title}</h3>
                <p className="text-sm text-gray-500">{article.date} | {article.category} | {article.author}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

    </>

  );
};

export default RecentEvents;
